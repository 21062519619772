@media print {
  /* esconder tudo */
  body * {
    visibility: hidden;    
  }
  /* exibir somente o que tem na div para imprimir */
  #print, #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;

    font-size: 12px;
  }  
}