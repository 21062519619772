#showItensTable{
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

#ask_showItensTable{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#containerTitle_showItensTable{
  display: flex;
  flex-grow: 0;
  background-color: rgb(7, 169, 161);
  margin-bottom: 2px;
}  

#title_showItensTable{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 5px;
  height: 40px;
  background-color: rgb(7, 169, 161);
  border-color: white;
  border-width: 2px;
  border-style: solid;
}

#title_showItensTable label{
  font-size: 16px;
  font-weight: 600;
  color: white;
}

#containerItens_showItensTable{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* /////////////////////////////////
/////         Addition          ////
///////////////////////////////////  */

.addition_showItensTable{
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  background-color: rgb(241, 242, 242);

  padding: 10px 15px;
  margin-top: 8px;
}

.addition_flexRow_showItensTable{
  display: flex;
  flex-direction: row;
}

.addition_flexRowCenter_showItensTable{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addition_fontWeight800_paddingRight5_showItensTable{
  font-weight: 800;
  padding-right: 5px;
}

@media all and (max-width: 379px){
  .addition_detail_showItensTable{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 1;
  }

  .addition_containerQuantityPrice_showItensTable{
    display: flex;
    flex-direction: column;
  }
}

@media all and (max-width: 599px){
  .items_containerDescriptionResumeUtilQuantity_showItensTable{
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    margin-top: 5px;
  }

  .items_containerUnit_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerPriceAdditionDiscountTotal_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin-top: 5px;
  }
}

@media all and (min-width: 380px) and (max-width: 599px){
  .addition_detail_showItensTable{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 1;
  }

  .addition_containerQuantityPrice_showItensTable{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media all and (min-width: 600px) and (max-width: 879px){
  .addition_detail_showItensTable{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 1;
  }

  .items_containerDescriptionResumeUtilQuantity_showItensTable{
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-top: 5px;
  }

  .items_containerUnit_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }

  .items_containerQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    margin-left: 10px;
  }

  .items_containerPriceAdditionDiscountTotal_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    margin-top: 5px;
  }
}

@media all and (min-width: 881px){
  .addition_detail_showItensTable{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 1;
  }

  .addition_containerQuantityPrice_showItensTable{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* /////////////////////////////////
/////        Composition        ////
/////////////////////////////////// */

.composition_showItensTable{
  background-color: rgb(241, 242, 242);
  padding: 5px;
  margin-top: 8px;
}

.compositionCancelled_showItensTable{
  background-color: red;
  padding: 5px;
  margin-top: 8px;
}

.composition_detail_showItensTable{
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.composition_quantity_showItensTable{
  display: flex;
  flex-direction: row;
}

/* /////////////////////////////////
/////      CompositionPart      ////
/////////////////////////////////// */

.compositionPart_showItensTable{
  padding: 5px;
  margin-top: 5px;
}

/* /////////////////////////////////
/////          Items            ////
/////////////////////////////////// */

.items_buttonCancel_showItensTable{
  display: flex;
  flex-grow: 0;
  align-items: center;
  margin-right: 6px;
}

.items_buttonCancel_showItensTable img {
  display: flex;
  flex-grow: 0;
  height: 28px;
  width: 28px;
}

.items_buttonExtendRetract_showItensTable{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
}

.items_buttonExtendRetract_showItensTable img{
  display: flex;
  flex-grow: 0;
  height: 28px;
  width: 28px;
}

.items_containerDescriptionDescriptionResumeUnitQuantity_showItensTable{
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.items_descricaoR_showItensTable{
  display: flex;
  flex-direction: row;
  flex-grow: 1;  
}

.items_descricaoRTitle_showItensTable{
  font-weight: 800;
  padding-right: 5px;
}

.items_containerUnitTitle_showItensTable{
  padding-right: 5px;
}

.items_containerUnitTitle_showItensTable label{
  font-weight: 800;
}

.items_containerQuantityTitle_showItensTable{
  padding-right: 5px;
}

.items_containerQuantityTitle_showItensTable label{
  font-weight: 800;
}

.items_containerPrice_showItensTable{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.items_containerPriceTitle_showItensTable{
  padding-right: 5px;
}

.items_containerPriceTitle_showItensTable label{
  font-weight: 800;
}

.items_containerDiscount_showItensTable{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.items_containerDiscountTitle_showItensTable{
  padding-right: 5px;
}

.items_containerDiscountTitle_showItensTable label{
  font-weight: 800;
}

.items_containerComposition_showItensTable{
  margin-top: 10px;
}

.items_containerTitleComposition_showItensTable{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(114,112,111);
  padding: 5px;
}

.items_titleComposition_showItensTable{
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.items_titleComposition_showItensTable label{
  color: white;
  font-weight: 800;
}

.items_observation_showItensTable{
  margin-top: 10px;
}

.items_discountAddition_showItensTable{
  display: flex;
  flex-grow: 0,;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 169, 161);
  border-radius: 5px;
  margin-top: 10px;
  height: 47px;
}
 
.items_discountAddition_showItensTable img{
  display: flex;
  flex-grow: 0;
  margin-right: 10px;
  height: 28px;
  width: 28px;
}
 
.items_discountAddition_showItensTable label{
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.items_colorWhite800_showItensTable{
  color: white;
  font-weight: 800;
}

.items_colorWhite800_paddingLeft5_showItensTable{
  color: white;
  font-weight: 800;
  padding-left: 5px;
}

.items_flexGrow0_showItensTable{
  display: flex;
  flex-grow: 0;
}

@media all and (max-width: 399px) {
  .items_detail_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    padding: 5px;
    background-color: rgb(114,112,111);
  }

  .items_detailCancelled_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    padding: 5px;
    background-color: red;
  }

  .items_containerCodeDescriptionResumed_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
  }

  .items_descriptionResumedValue_showItensTable{
    color: white;
    font-weight: 800;
    text-align: center;
  }

  .items_containerPriceOtherValueDiscountValue_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
  }

  .items_descriptionResumedButtons_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    margin-top: 10px;
  }

  .items_informations_showItensTable{
    padding-top: 5px;
  }

  .items_containerUtilQuantity_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .items_containerPriceAddition_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .items_containerDiscountTotal_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerAddition_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerAdditionTitle_showItensTable{
    padding-right: 5px;
  }

  .items_containerAdditionTitle_showItensTable label{
    font-weight: 800;
  }

  .items_containerTotal_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }
  
  .items_containerTotalTitle_showItensTable{
    padding-right: 5px;
  }
  
  .items_containerTotalTitle_showItensTable label{
    font-weight: 800;
  }  
}

@media all and (min-width: 400px) and (max-width: 599px) {
  .items_containerUtilQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
}

@media all and (min-width: 400px) and (max-width: 879px) {
  .items_detail_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    padding: 5px;
    background-color: rgb(114,112,111);
  }

  .items_detailCancelled_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    padding: 5px;
    background-color: red;
  }

  .items_containerCodeDescriptionResumed_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }

  .items_descriptionResumedValue_showItensTable{
    color: white;
    font-weight: 800;
    text-align: center;
    padding-left: 5px;
  }

  .items_containerPriceOtherValueDiscountValue_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }

  .items_descriptionResumedButtons_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    max-width: 70px;
  }

  .items_informations_showItensTable{
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .items_containerUtilQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }  

  .items_containerPriceAddition_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .items_containerDiscountTotal_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .items_containerAddition_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .items_containerAdditionTitle_showItensTable{
    padding-right: 5px;
  }
  
  .items_containerAdditionTitle_showItensTable label{
    font-weight: 800;
  }

  .items_containerTotal_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  
  .items_containerTotalTitle_showItensTable{
    padding-right: 5px;
  }
  
  .items_containerTotalTitle_showItensTable label{
    font-weight: 800;
  }  
}

@media all and (min-width: 600px) and (max-width: 879px) {
  .items_containerUtilQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }
}

@media all and (max-width: 799px){
  .items_descriptionResumed_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
}

@media all and (min-width: 800px) and (max-width: 879px) {
  .items_descriptionResumed_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
}

@media all and (min-width: 800px) and (max-width: 919px) {
  .items_descriptionResumedButtons_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    margin-top: 4px;
  }
}

@media all and (max-width: 879px){
  .items_showItensTable{
    margin-top: 5px;
    margin-bottom: 5px
  }

  .items_containerUnit_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }
}

@media all and (min-width: 880px){
  .items_showItensTable{
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .items_descriptionResumed_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  .items_containerCodeDescriptionResumed_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }

  .items_descriptionResumedValue_showItensTable{
    color: white;
    font-weight: 800;
    text-align: center;
    padding-left: 5px;
  }

  .items_containerPriceOtherValueDiscountValue_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }

  .items_containerDescriptionResumeUtilQuantity_showItensTable{
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    margin-top: 5px;
  }

  .items_containerUnit_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerPriceAdditionDiscountTotal_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin-top: 5px;
  }
}

@media all and (min-width: 880px) and (max-width: 899px) {
  .items_informations_showItensTable{
    padding-top: 5px;
  }

  .items_containerUtilQuantity_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .items_containerPriceAddition_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .items_containerDiscountTotal_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerAddition_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }

  .items_containerAdditionTitle_showItensTable{
    padding-right: 5px;
  }

  .items_containerAdditionTitle_showItensTable label{
    font-weight: 800;
  }

  .items_containerTotal_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }
  
  .items_containerTotalTitle_showItensTable{
    padding-right: 5px;
  }
  
  .items_containerTotalTitle_showItensTable label{
    font-weight: 800;
  }  
}

@media all and (min-width: 900px){
  .items_informations_showItensTable{
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .items_containerUtilQuantity_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .items_containerPriceAddition_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .items_containerDiscountTotal_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 5px;
  }  

  .items_containerAddition_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .items_containerAdditionTitle_showItensTable{
    padding-right: 5px;
  }
  
  .items_containerAdditionTitle_showItensTable label{
    font-weight: 800;
  }

  .items_containerTotal_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  
  .items_containerTotalTitle_showItensTable{
    padding-right: 5px;
  }
  
  .items_containerTotalTitle_showItensTable label{
    font-weight: 800;
  }  
}

@media all and (min-width: 880px) and (max-width: 919px) {
  .items_detail_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 5px;
    background-color: rgb(114,112,111);
  }

  .items_detailCancelled_showItensTable{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 5px;
    background-color: red;
  }
}

@media all and (min-width: 920px){
  .items_detail_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    padding: 5px;
    background-color: rgb(114,112,111);
  }

  .items_detailCancelled_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    padding: 5px;
    background-color: red;
  }

  .items_descriptionResumedButtons_showItensTable{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }
}

/* /////////////////////////////////
/////          Global           ////
/////////////////////////////////// */

.colorWhite_showItensTable{
  color: white;
}

.colorWhite800_showItensTable{
  color: white;
  font-weight: 800;
}

.paddingLeft5_showItensTable{
  padding-left: 5px;
}

.marginLeft5_showItensTable{
  margin-left: 5px;
}

@media all and (max-width: 579px){
  #containerTitle_showItensTable{
    display: flex;
    flex-grow: 0;
    background-color: rgb(7, 169, 161);
    margin-top: 2px;
    margin-bottom: 2px;
  }
}