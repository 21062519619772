#inputMasked{
  display: flex;
  flex-direction: column;
}

#descricaoLogin_inputMasked{
  margin-left: 10px;
  margin-bottom: 5px;
}

#descricaoLogin_inputMasked label{
  font-weight: 600;
}

#descricaoTela_inputMasked{
  margin-bottom: 5px;
}

#descricaoTela_inputMasked label{
  font-weight: 600;
}

#corpoFocuLogin_inputMasked{
  display: flex;
  flex-direction: row;
  border: 3px rgb(0, 177, 171) solid;
  border-radius: 20px;
}

#corpoUnFocuLogin_inputMasked{
  display: flex;
  flex-direction: row;
  border: 3px rgb(128, 130, 133) solid;
  border-radius: 20px;
}

#corpoFocuTela_inputMasked{
  display: flex;
  flex-direction: row;
  border: 2px rgb(0, 177, 171) solid;
}

#corpoUnFocuTela_inputMasked{
  display: flex;
  flex-direction: row;
  border: 2px rgb(128, 130, 133) solid;  
}

.corpoImagem_inputMasked{
  margin-top: 10px;
  padding-left: 26px;
  width: 12%;
}

.corpoImagem_inputMasked img{
  width: 27px;
  height: 34px;
}

#corpoCampoImagem_inputMasked{
  display: flex;
  flex-grow: 1;

  padding-left: 26px;
  padding-right: 26px;
}

#corpoCampoSemImagem_inputMasked{
  display: flex;
  flex-grow: 1;

  padding-left: 26px;
  padding-right: 26px;
}

.obrigatorio_inputMasked{
  border: none;
  outline: none;

  border-bottom: 2px solid red;

  font-family: Poppins-SemiBold;
  color: #555555;
  line-height: 1.2;
  
  display: block;
  height: 34px;
  width: 100%;
  background: transparent;
  padding: 0 5px;

  margin: 3px 0;
}

.naoObrigatorio_inputMasked{
  border: none;
  outline: none;  

  border-bottom: 2px solid silver;

  font-family: Poppins-SemiBold;
  color: #555555;
  line-height: 1.2;
  
  display: block;
  height: 34px;
  width: 100%;
  background: transparent;
  padding: 0 5px;  

  margin: 3px 0;
}