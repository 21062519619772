#compositionItem{
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 100%;
}

#containerItems_compositionItem{
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

#buttonConfirm_compositionItem{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 169, 161);
  border-radius: 10px;
  height: 67px;
}

#buttonConfirm_compositionItem label{
  color: white;
  font-size: 16px;
  font-weight: 600;
}

#buttonCancel_compositionItem label{
  color: white;
  font-size: 16px;
  font-weight: 600;
}

/* /////////////////////////////////
/////         addition          ////
/////////////////////////////////// */

.addition_descriptionQuantityPrice_compositionItem{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 20px;
}

.addition_quantity_compositionItem{
  display: flex;
  flex-direction: row;
}

.addition_quantityLabel_compositionItem{
  font-weight: 800;
  margin-right: 5px;
}

.addition_price_compositionItem{
  display: flex;
  flex-direction: row;
}

.addition_priceLabel_compositionItem{
  font-weight: 800;
  margin-right: 5px;
}

.addition_quantityButtons_compositionItem img{
  display: flex;
  flex-grow: 0;
  width: 28px;
  height: 28px;
}

/* /////////////////////////////////
/////        composition        ////
/////////////////////////////////// */

.composition_compositionItem{
  background-color: rgb(241, 242, 242);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 8px;
}

.compositionCancelled_compositionItem{
  background-color: red;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 8px;
}

.composition_information_compositionItem{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.composition_informationDescription_compositionItem{
  color: white;
}

.composition_informationQuantity_compositionItem{
  display: flex;
  flex-direction: row;
}

.composition_descriptionText_informationQuantity_compositionItem{
  font-weight: 800;
  padding-right: 5px;
}

.composition_descriptionText_informationQuantityCancelled_compositionItem{
  color: white;
  font-weight: 800;
  padding-right: 5px;
}

.composition_descriptionValue_informationQuantity_compositionItem{
  color: white;
}

.composition_buttons_compositionItem img{
  display: flex;
  flex-grow: 0;
  width: 28px;
  height: 28px;
}

/* /////////////////////////////////
/////      compositionPart      ////
/////////////////////////////////// */

.compositionPart_compositionItem{
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
}

/* /////////////////////////////////
/////           items           ////
/////////////////////////////////// */

.items_compositionItem{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}

.items_quantityPriceResumed_compositionItem{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

.items_additionPriceResumed_compositionItem{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

.items_buttonsResumed_compositionItem img{
  display: flex;
  flex-grow: 0;
  height: 28px;
  width: 28px;
}

.items_descriptionR_compositionItem{
  padding-right: 5px;
}

.items_descriptionR_compositionItem label{
  font-weight: 800;
}

.item_containerUnit_compositionItem{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-basis: 1;
}  

.items_unit_compositionItem{
  padding-right: 5px;
}

.items_unit_compositionItem label{
  font-weight: 800;
}

.items_quantity_compositionItem{
  padding-right: 5px;
}

.items_quantity_compositionItem label{
  font-weight: 800;
}

.items_containerPriceAdditionTotal_compositionItem{
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2px;
}

.items_price_compositionItem{
  padding-right: 5px;
}

.items_price_compositionItem label{
  font-weight: 800;
}

.items_additionTitle_compositionItem{
  padding-right: 5px;
}

.items_additionTitle_compositionItem label{
  font-weight: 800;
}

.items_total_compositionItem{
  padding-right: 5px;
}

.items_total_compositionItem label{
  font-weight: 800;
}

.items_containerComposition_compositionItem{
  margin-top: 10px;
}

.items_containerTitleComposition_compositionItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: silver;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.items_titleComposition_compositionItem{
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.items_titleComposition_compositionItem label{
  color: white;
  font-weight: 800;
}

.items_titleButtons_compositionItem{
  display: flex;
  flex-grow: 0;
  margin-right: 5px;
}

.items_titleButtons_compositionItem img{
  display: flex;
  flex-grow: 0;
  height: 28px;
  width: 28px;
}

.items_observation_compositionItem{
  margin-top: 10px;
}

/* /////////////////////////////////
/////          global         ////
/////////////////////////////////// */

.whiteWeigth800_compositionItem{
  color: white; font-weight: 800;
}

.textAlignCenter_compositionItem{
  text-align: center;
}

.paddingLeft5_compositionItem{
  padding-left: 5px;
}

@media all and (max-width: 259px){
  .addition_containerQuantitySelected_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    margin-top: 8px;
  }

  .addition_quantityButtons_compositionItem{
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }
}

.items_containerDetail_compositionItem{
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.items_detail_compositionItem{
  display: flex;
  flex-direction: column;
}

@media all and (min-width: 260px){
  .addition_quantityButtons_compositionItem{
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: flex-end;
  }
}

@media all and (min-width: 260px) and (max-width: 499px){
  .addition_containerQuantitySelected_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
  }
}

@media all and (max-width: 299px){
  .items_containerUnitQuantity_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .addition_quantitySelected_compositionItem{
    display: flex;
    flex-grow: 0;
    margin-top: 5px;
    min-width: 100px;
    max-width: 100px;
  }
}

@media all and (min-width: 300px) and (max-width: 329px){
  .addition_quantitySelected_compositionItem{
    display: flex;
    flex-grow: 0;
    margin-top: 5px;
    min-width: 150px;
    max-width: 150px;
  }
}

@media all and (min-width: 300px) and (max-width: 589px){
  .items_containerUnitQuantity_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
}

@media all and (min-width: 330px) and (max-width: 499px){
  .addition_quantitySelected_compositionItem{
    display: flex;
    flex-grow: 0;
    margin-top: 5px;
    min-width: 180px;
    max-width: 180px;
  }
}

@media all and (max-width: 359px){
  .items_containerDescriptionResumed_compositionItem{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: silver;
    padding: 5px;
  }

  .items_otherInformationResumed_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
  }

  .items_buttonsResumed_compositionItem{
    display: flex;
    flex-grow: 0;
    justify-content: center;
    margin-top: 5px;
  }

  .addition_quantityPrice_compositionItem{
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 360px){
  .items_containerDescriptionResumed_compositionItem{  
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    background-color: silver;
    padding: 5px;
  }

  .items_otherInformationResumed_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }

  .items_buttonsResumed_compositionItem{
    display: flex;
    flex-grow: 0;
    align-items: center;
    margin-right: 5px;
  }

  .addition_quantityPrice_compositionItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }  
}

@media all and (max-width: 389px){
  .composition_detail_compositionItem{
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }  

  .buttonsComposition_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    margin-top: 8px;
  }

  .items_containerDescriptionR_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

@media all and (min-width: 390px){
  .composition_detail_compositionItem{
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .buttonsComposition_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: center;
  }

  .items_containerDescriptionR_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }  
}

@media all and (max-width: 399px){
  #items_Description_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
  }

  #containerButtons_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin-top: 5px;
  }

  #buttonCancel_compositionItem{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    background-color: rgb(114,112,111);
    border-radius: 10px;
    margin-top: 5px;
    height: 67px;
  }

  .items_containerPriceAddition_compositionItem{
    display: flex;
    flex-grow: 0;
    flex-direction: column;
  }  
}

@media all and (min-width: 400px){
  #items_Description_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
  }

  #containerButtons_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    margin-top: 5px;
  }
  
  #buttonCancel_compositionItem{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    background-color: rgb(114,112,111);
    border-radius: 10px;
    margin-left: 5px;
    height: 67px;
  }

  .items_containerPriceAddition_compositionItem{
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 1;
  }
}

@media all and (max-width: 469px){
  .items_containerPrice_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  
  .items_containerAddition_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  
  .items_containerTotal_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
}

@media all and (min-width: 470px){
  .items_containerPrice_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }
  
  .items_containerAddition_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }
  
  .items_containerTotal_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }
}

@media all and (max-width: 499px){
  .addition_compositionItem{
    display: flex;
    flex-direction: column;
    background-color: rgb(241, 242, 242);
    padding: 5px 10px;
    margin-top: 8px;
  }
}

@media all and (min-width: 500px){
  .addition_compositionItem{
    display: flex;
    flex-direction: row;
    background-color: rgb(241, 242, 242);
    padding: 5px 10px;
    margin-top: 8px;
  }

  .addition_containerQuantitySelected_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    align-items: center;
    width: 150px;    
  }

  .addition_quantitySelected_compositionItem{
    display: flex;
    flex-grow: 1;
    padding-right: 5px;
  }
}

@media all and (max-width: 589px){
  .items_description_compositionItem
  {  
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    margin-top: 2px;
  }

  .items_containerQuantity_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-basis: 1;
  }  
}

@media all and (min-width: 590px){
  .items_description_compositionItem
  {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-top: 2px;
  }

  .items_containerUnitQuantity_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  }

  .items_containerQuantity_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-basis: 1;
    margin-left: 10px;
  }  
}

@media all and (max-width: 799px){
  .items_descriptionResumed_compositionItem{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
  }
}

@media all and (min-width: 800px){
  .items_descriptionResumed_compositionItem{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
  }
}