#loading_authentication{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 495px;
  width: 100%;
}

#loading_authentication img{
  margin-bottom: 10px;

  height: 32px; 
  width: 32px;  
}

#loading_authentication label{
  font-weight: 600;
  color: rgb(0, 177, 171);
}

.message_authentication{
  display: flex;
  flex-grow: 0;

  padding: 0 10px 0 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.messageError_authentication{
  font-weight: 600;
  color: red
}

.botoes_authentication{
  margin-top: 10px;

  border-width: 3 rgb(128, 130, 133) solid;
  border-radius: 10px;
  height: 52px;
  background-color: rgb(0, 177, 171);
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorWhite_authentication{
  color: white;
}

.fontWeight600_authentication{
  font-weight: 600;
}

.marginTop10_authentication{
  margin-top: 10px;
}