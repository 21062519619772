/* No File */
#datePicker{
  display: flex;
  flex-direction: column;
}

#descricaoLogin_datePicker{
  margin-left: 10px;
  margin-bottom: 5px;  
}

#descricaoLogin_datePicker label{  
  font-weight: 600;
}

#descricaoTela_datePicker{
  margin-bottom: 5px;
}

#descricaoTela_datePicker label{  
  font-weight: 600;
}

#corpoFocuLogin_datePicker{
  display: flex;
  flex-direction: row;
  border: 3px rgb(0, 177, 171) solid;
  border-radius: 20px;
}

#corpoUnFocuLogin_datePicker{
  display: flex;
  flex-direction: row;
  border: 3px rgb(128, 130, 133) solid;
  border-radius: 20px;
}

#corpoFocuTela_datePicker{
  display: flex;
  flex-direction: row;
  border: 2px rgb(0, 177, 171) solid;
}

#corpoUnFocuTela_datePicker{
  display: flex;
  flex-direction: row;
  border: 2px rgb(128, 130, 133) solid;  
}

.corpoImagem_datePicker{
  margin-top: 10px;
  padding-left: 26px;
  width: 12%;
}

.corpoImagem_datePicker img{
  width: 27px;
  height: 34px;
}

#corpoCampoImagem_datePicker{
  display: flex;
  flex-grow: 1;

  padding-left: 26px;
  padding-right: 26px;
}

#corpoCampoSemImagem_datePicker{
  display: flex;
  flex-grow: 1;

  padding-left: 26px;
  padding-right: 26px;
}

.obrigatorio_datePicker{
  border: none;
  outline: none;

  border-bottom: 2px solid red;

  font-family: Poppins-SemiBold;
  color: #555555;
  line-height: 1.2;
  
  display: block;
  height: 34px;
  width: 100%;
  background: transparent;
  padding: 0 5px;

  margin: 3px 0;
}

.naoObrigatorio_datePicker{  
  border: none;
  outline: none;  

  border-bottom: 2px solid silver;

  font-family: Poppins-SemiBold;
  color: #555555;
  line-height: 1.2;
  
  display: block;
  height: 34px;
  width: 100%;
  background: transparent;
  padding: 0 5px;  

  margin: 3px 0;
}