#tableCenter{
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

#body_tableCenter{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

#bodyMenu_tableCenter{
  display: flex;
  flex-grow: 0;
  flex-direction: row;
}

#bodyContent_tableCenter{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#bodyHeader_tableCenter{
  display: flex;
  flex-direction: row;
  background-color: rgb(0, 177, 171);

  padding-top: 15px;
  padding-bottom: 15px;
}

#bodyHeaderMenu_tableCenter{
  display: flex;
  justify-content: flex-start;
  align-items: center;
    
  background-color: rgb(0, 177, 171);
  padding-left: 10px;
}

#bodyHeaderMenu_tableCenter img{
  height: 27px;
  width: 32px;
}

#bodyHeaderTitle_tableCenter{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center
}

#bodyHeaderTitle_tableCenter label{
  color: white;
  font-size: 18px;
  font-weight: 600;
}

#bodyHeaderLogo_tableCenter{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  flex-grow: 0;
  padding-right: 20px;
}

#loading_tableCenter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 495px;
  width: 100%;
}

#loading_tableCenter img{
  margin-bottom: 10px;

  height: 32px;
  width: 32px;
}

#loading_tableCenter label{
  font-weight: 600;
  color: rgb(0, 177, 171);
}

#content_tableCenter{
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 10px;
}

#bodyMessageWarning_tableCenter{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  padding-top: 10px;
}
  
#messageWarning_tableCenter{
  font-weight: 600;
  color: rgb(0, 177, 171)
}
  
#bodyMessageError_tableCenter{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

#messageError_tableCenter{
  font-weight: 600;
  color: red
}

#bodySearch_tableCenter{
  display: flex;  
  flex-direction: row;
  flex-grow: 0;  
  max-width: 180px; 
}

#bodySearchNumber_tableCenter{
  display: flex;
  flex-grow: 1;
}

#bodySearchButton_tableCenter{
  display: flex;
  flex-grow: 0;
  align-items: flex-end;

  margin-left: 5px;
}

#bodyRecords_tableCenter{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 10px;
}

.record_tableCenter{
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  
  height: 80px;
  width: 80px;
  padding: 2px;
  margin: 10px 2px;  
}

.recordBodyImagem_tableCenter{
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.recordImagem_tableCenter{
  height: 48px;
  width: 48px;
}

.recordBodyText_tableCenter{
  display: flex;
  flex-grow: 0;
  justify-content: center; 
}

.recordText_tableCenter{    
  font-weight: 600;
}

.sizeButton44_tableCenter{
  height: 44px;
  width: 44px;
  
  padding: 5px;
  background-color: rgb(241, 242, 242);
}