#main_Questionnaire{
  display: flex;  
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#containerAsk_Questionnaire{
  margin-bottom: 20px;
}

#ask_Questionnaire
{
  font-family: Poppins-SemiBold;
  font-size: 16px;
  font-weight: 800;
}

#buttonConfirm_Questionnaire{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;  
  background-color: rgb(7, 169, 161);  
  height: 67px;
  width: 250px;  
}

#buttonCancel_Questionnaire{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;  
  background-color: rgb(114,112,111);  
  margin-top: 5px;
  height: 67px;
  width: 250px;
}

.textButton_Questionnaire{
  font-family: Poppins-SemiBold;
  font-size: 16px;
  font-weight: 600;
  color: white;
}