/* No File */
#input{
  display: flex;
  flex-direction: column;
}

#descricaoLogin_input{
  margin-left: 10px;
  margin-bottom: 5px;  
}

#descricaoLogin_input label{  
  font-weight: 600;
}

#descricaoTela_input{
  margin-bottom: 5px;
}

#descricaoTela_input label{  
  font-weight: 600;
}

#corpoFocuLogin_input{
  display: flex;
  flex-direction: row;
  border: 3px rgb(0, 177, 171) solid;
  border-radius: 20px;
}

#corpoUnFocuLogin_input{
  display: flex;
  flex-direction: row;
  border: 3px rgb(128, 130, 133) solid;
  border-radius: 20px;
}

#corpoFocuTela_input{
  display: flex;
  flex-direction: row;
  border: 2px rgb(0, 177, 171) solid;
}

#corpoUnFocuTela_input{
  display: flex;
  flex-direction: row;
  border: 2px rgb(128, 130, 133) solid;  
}

.corpoImagem_input{
  margin-top: 10px;
  padding-left: 26px;
  width: 12%;
}

.corpoImagem_input img{
  width: 27px;
  height: 34px;
}

#corpoCampoImagem_input{
  display: flex;
  flex-grow: 1;

  padding-left: 26px;
  padding-right: 26px;
}

#corpoCampoSemImagem_input{
  display: flex;
  flex-grow: 1;

  padding-left: 26px;
  padding-right: 26px;
}

.obrigatorio_input{
  border: none;
  outline: none;

  border-bottom: 2px solid red;

  font-family: Poppins-SemiBold;
  color: #555555;
  line-height: 1.2;
  
  display: block;
  height: 34px;
  width: 100%;
  background: transparent;
  padding: 0 5px;

  margin: 3px 0;
}

.naoObrigatorio_input{  
  border: none;
  outline: none;  

  border-bottom: 2px solid silver;

  font-family: Poppins-SemiBold;
  color: #555555;
  line-height: 1.2;
  
  display: block;
  height: 34px;
  width: 100%;
  background: transparent;
  padding: 0 5px;  

  margin: 3px 0;
}

/* File */
.espacoInput_input{
  margin-top: 20px;
}

.file_Input{
  display: none;
}

#login_Input{
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.foto_Input{
  border-radius: 10%;
  height: 100px;
  width: auto;
}

.image_Input{
  border-radius: 10%;
  height: 64px;
  width: auto;
}