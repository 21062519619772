#main_SaleQuantity{
  display: flex;
  flex-grow: 1;
}

#container1_main_SaleQuantity{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

#container2_container1_main_SaleQuantity{
  width: 300
}

#textShortDescriptionProduct_container2_container1_main_SaleQuantity{
  font-size: 16px;
  font-weight: 800;
  color: rgb(128, 130, 133);
  margin-bottom: 5px;
}

#textUnitAbbreviation_container2_container1_main_SaleQuantity{
  font-size: 16px;
  font-weight: 800;
  color: rgb(128, 130, 133);
  margin-left: 5px;
  margin-bottom: 5px;
}

#quantitySelected_container2_container1_main_SaleQuantity{
  margin-top: 10px;
}

#buttonConfirm_container2_container1_main_SaleQuantity{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 169, 161);
  border-radius: 10px;
  margin-top: 10px;
  height: 67px;
  width: 100%;
}

#buttonCancel_container2_container1_main_SaleQuantity{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(114,112,111);
  border-radius: 10px;
  margin-top: 5px;
  height: 67px;
  width: 100%;
}

.button_container2_container1_main_SaleQuantity{
  font-size: 16px;
  font-weight: 600;
  color: white;
}