.titulo {
  border-radius: 5px;

  margin-top:0px;
  margin-bottom:5px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.verde {
  color:#fff;
  background-color: rgb(0, 177, 171);
}